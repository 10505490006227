import {Collapse} from "bootstrap";

let cookieModal = document.getElementById("cookie-consent");
let cookieModalInstance = Collapse.getOrCreateInstance(cookieModal, {
    toggle: false
});

if (typeof SHOW_COOKIES !== "undefined" && SHOW_COOKIES === "")
    cookieModalInstance.show();

if (cookieModal !== null) {
    cookieModal.querySelectorAll(".form-check").forEach(item => {
        item.addEventListener("click", e => {
            if (e.target.querySelector("input") !== null)
                e.target.querySelector("input").checked = !e.target.querySelector("input").checked ;
        })
    })

    document.getElementById("cookie-details").addEventListener('show.bs.collapse', function () {
        document.querySelector(".cookie-consent--details-hide").classList.add('d-none');
        document.querySelector(".cookie-consent--details-show").classList.remove('d-none');
    })

    function submitCookie() {
        let cookieConsentForm = cookieModal.querySelector('form');
        let formData = new FormData(cookieConsentForm);

        fetch(cookieConsentForm.action ? cookieConsentForm.action : location.href, {
            body: formData,
            method: "POST"
        }).then( res => {
            if (res.status >= 200 && res.status < 300)
                cookieModalInstance.hide();
        });
    }

    function checkType(accept = false) {
        let cookieConsentForm = cookieModal.querySelector('form');
        if (cookieConsentForm !== null) {
            cookieConsentForm.querySelectorAll("input[type='checkbox']:not([disabled])").forEach(item => {
                item.checked = accept;
            });

            submitCookie();
        }
    }

    cookieModal.querySelector("form").addEventListener("submit", e => {
        e.preventDefault();
        submitCookie();
    })

    document.querySelector(".cookies-accept-all").addEventListener("click", _ => checkType(true));
    document.querySelector(".cookies-refuse-all").addEventListener("click", _ => checkType());

    if (document.querySelector(".display-cookies"))
        document.querySelector(".display-cookies").addEventListener("click", _ => cookieModalInstance.show());
}
