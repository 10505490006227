import {Modal} from 'bootstrap';

let modalLanguageForm = null;

function submitForm(e, refresh = false) {
    let formData = new FormData(modalLanguageForm);

    if (refresh) {
        formData.delete('language[_token]');

        if (e.target.name === "language[market]")
            formData.delete('language[language]')
    } else
        e.preventDefault();

    fetch(modalLanguageForm.getAttribute("action"), {
        method: modalLanguageForm.getAttribute('method'),
        body: formData
    })
        .then(async res => {
            var text = res.text();

            try {
                return JSON.parse(await text);
            } catch (e) {
                return text;
            }

        }).then(data => {
            if (typeof data === 'object' && data !== null) {
                if ("html" in data) {
                    let view = new DOMParser().parseFromString(data.html, "text/html");
                    modalLanguageForm.querySelector(".form-block-xhr").innerHTML = view.querySelector(".form-block-xhr").innerHTML;
                }

                if ("reload" in data) {
                    if (!data.reload)
                        Modal.getInstance(document.getElementById("modal-language-selection")).hide();
                    else
                        window.location.reload();
                }
            } else {
                let view = new DOMParser().parseFromString(data, "text/html");
                modalLanguageForm.querySelector(".form-block-xhr").innerHTML = view.querySelector(".form-block-xhr").innerHTML;
            }
        }).then(_ => {
            initEventSelection(refresh);
        });
}

function refreshModal() {
    fetch(document.location.href, {
        method: "GET",
    })
        .then(res => res.text())
        .then(data => {
            let view = new DOMParser().parseFromString(data, "text/html");

            modalLanguage.innerHTML = view.getElementById("modal-language-selection").innerHTML;
        }).then(_ => {
        initEventSelection(false);
    });
}

function initEventSelection(refresh = false) {
    modalLanguageForm = modalLanguage.querySelector('form');
    modalLanguageForm.querySelectorAll("#language_market").forEach(item => {
         item.addEventListener("change", e => submitForm(e, true));
     });

    if (!refresh)
        modalLanguageForm.addEventListener("submit", e => submitForm(e))
}

let modalLanguage = document.getElementById("modal-language-selection");

if (modalLanguage !== null) {
    if (OPEN_LANGUAGE_MODAL === "1")
        Modal.getOrCreateInstance(modalLanguage).show();

    modalLanguage.querySelector("form").addEventListener("submit", e => submitForm(e));
    initEventSelection(true);

    document.querySelector(".language-switcher [data-bs-target='#modal-language-selection']")
        .addEventListener("click", e => refreshModal(e));

    modalLanguage.addEventListener('hidden.bs.modal', e => {
        fetch(modalLanguage.querySelector(".btn-close").dataset.reset, {
            method: "POST",
        }).then(r => r.json())
            .then(data => {
                if ("reload" in data) {
                    if (data.reload)
                        window.location.reload();
                }
            })
            .catch(r => {});
    });
}

