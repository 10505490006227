import Cropper from "cropperjs";

function ImageEdition() {
    let result = document.querySelector('.crop'),
        save = document.querySelector('.save'),
        cropped = document.querySelector('.cropped'),
        upload = document.querySelector('#file'),
        step1 = document.querySelector('.step1'),
        step2 = document.querySelector('.step2'),
        step3 = document.querySelector('.step3'),
        step1_container = document.querySelector('.step1-container'),
        step2_container = document.querySelector('.step2-container'),
        step3_container = document.querySelector('.step3-container'),
        back_step1 = document.querySelector('.back-step1'),
        back_step2 = document.querySelector('.back-step2'),
        validate = document.querySelector('.validate'),
        cropper = '';

    if (back_step1) {
        back_step1.addEventListener('click', e => {
            upload.value = null;
            step1.classList.toggle('visible');
            step2.classList.toggle('visible');
            step1_container.classList.toggle('visible');
            step2_container.classList.toggle('visible');
        });
    }

    if (back_step2) {
        back_step2.addEventListener('click', e => {
            step2.classList.toggle('visible');
            step3.classList.toggle('visible');
            step2_container.classList.toggle('visible');
            step3_container.classList.toggle('visible');
        });
    }

    if (validate) {
        validate.addEventListener('click', async e => {
            e.preventDefault();
            await fetch(validate.getAttribute("href"), {
                method: 'POST',
                body: cropped.src
            }).then(res => res.json()).then(res => {
                if (res.save === true)
                    window.location.href = validate.getAttribute("data-redirect");
            });
        });
    }

    if (upload) {
        upload.addEventListener('change', e => {
            if (e.target.files.length) {
                const reader = new FileReader();
                reader.onload = e => {
                    if (e.target.result) {
                        let img = document.createElement('img');
                        img.id = 'image';
                        img.src = e.target.result;
                        result.innerHTML = '';
                        result.appendChild(img);
                        save.classList.remove('hide');
                        cropper = new Cropper(img, {aspectRatio: 1, viewMode: 3});
                        step1.classList.toggle('visible');
                        step2.classList.toggle('visible');
                        step1_container.classList.toggle('visible');
                        step2_container.classList.toggle('visible');
                    }
                };
                reader.readAsDataURL(e.target.files[0]);
            }
        });
    }

    if (save) {
        save.addEventListener('click', e => {
            e.preventDefault();
            cropped.src = cropper.getCroppedCanvas({
                width: 300
            }).toDataURL();
            step2.classList.toggle('visible');
            step3.classList.toggle('visible');
            step2_container.classList.toggle('visible');
            step3_container.classList.toggle('visible');
        });
    }
}

if (document.getElementById('user-avatar')) {
    ImageEdition();
}
