export function showHidePassword() {
    let subject = document.querySelectorAll(".show-hide-password-input"), a;
    if (subject.length > 0) {
        for (a = 0; a < subject.length; ++a) {
            if(!findNextElementWithClass(subject[a], 'show-hide-password')) {

                let div = subject[a].closest('.show-hide-password-container');

                if (div === null) {
                    let div = document.createElement("div");
                    div.classList.add("show-hide-password-container");
                    subject[a].parentNode.insertBefore(div, subject[a]);
                    div.appendChild(subject[a]);
                }

                subject[a].insertAdjacentHTML(
                    'afterend',
                    '<div class="show-hide-password"><a href="#" tabindex="-1"><i class="fa fa-eye-slash" aria-hidden="true"></i></a></div></div>',
                );
                if(findNextElementWithClass(subject[a], 'show-hide-password')) {
                    subject[a].nextSibling.addEventListener("click", e => toggleShowHide(e));
                }
            }
        }
    }
}

function toggleShowHide(e) {
    e.preventDefault();
    let path = e.target.parentElement.parentElement;
    if(path.querySelector('input.show-hide-password-input') === null) {
        path = e.target.parentElement.parentElement.parentElement;
    }
    toggleChildren(path.querySelector('input.show-hide-password-input'));
    toggleImage(path.querySelector('i.fa'));
}


function toggleChildren(el) {
    if (el.type === 'password') {
        el.type = 'text';
    } else {
        el.type = 'password';
    }
}

function toggleImage(el) {
    if (el.classList.contains('fa-eye-slash')) {
        el.classList.remove('fa-eye-slash');
        el.classList.add('fa-eye');
    } else {
        el.classList.remove('fa-eye');
        el.classList.add('fa-eye-slash');
    }
}

function findNextElementWithClass(element, className) {
    let nextElement = element.nextElementSibling;
    return nextElement && nextElement.classList.contains(className);

}

showHidePassword();
